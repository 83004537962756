.mywork {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    text-align: center;
}

.mywork-header {
    text-align: left;
    margin: 70px 0 0 100px;
}

.mywork-header h4 {
    font-weight: 600;
}

@media (max-width: 800px) {
    .mywork-header {
        margin-left: 0;
        text-align: center;
    }
}

.mywork-projects {
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*justify-content: space-around;*/
    column-count: 3;
    padding: 30px;
}

@media (max-width: 1100px) {
    .mywork-projects {
        column-count: 2;
    }
}

@media (max-width: 800px) {
    .mywork-projects {
        column-count: 1;
    }
}
