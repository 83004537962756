body {
    margin: 0;
    padding: 0;
    /*font-family: Lato, sans-serif;*/

}

.app {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: #f8f8f8;
    /*font-family: MinionPro-Regular,Georgia,Times,Times New Roman,serif;*/
    font-family: 'Poppins', sans-serif;
    color: #3f3a37;
    -webkit-font-smoothing: antialiased;
    width: 100%;
}

@media (max-width: 800px) {
    body {
        overflow-x: hidden;
    }
}
