.header {
    text-align: center;
}

.banner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 50px 0 50px 0;
    margin: 50px;
}

.banner-title {
    flex: 2;
    margin: auto;
}

.banner-photo {
    padding-bottom: 40px;
    flex: 1;
}

.banner a {
    text-decoration: none;
    color: #3f3a37;
}

.banner-title-big {
    font-weight: 600;
}

.banner-title-small {
    color: rgb(242, 182, 50);
}

.banner-photo img {
    z-index: 10;
    width: 230px;
    height: auto;
    border: 1px solid rgba(242, 182, 50, 0.56);
    background-clip: content-box;
    box-shadow: 0 0 0 4px rgba(39, 18, 7, 0.78);
    border-radius: 50%;
}


@media (max-width: 800px) {
    .banner {
        flex-direction: column-reverse;
        justify-content: center;
        margin: 30px 0 30px 0;
        /*padding: 50px;*/
    }

    .banner-title-big {
        font-weight: 600;
    }
    .banner-title-big a {
        white-space: pre-wrap;
    }
    .banner-title {
        margin: 0;
    }

    .banner-photo {
        margin: auto;
    }
}
@media (max-width: 400px) {
    .banner-title-big a {
    }
    .banner-title-small {
    }
}
