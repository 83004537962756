.link {
    border: 1px solid rgba(82, 49, 49, 0.16);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 3px 8px 3px 8px;
    background-color: #baafaf;
    font-size: 15px;
    margin-right: 5px;
    cursor: pointer;
    text-decoration: none;

    -webkit-transition : 0.35s all ease;
    -moz-transition : 0.35s all ease;
    -o-transition : 0.35s all ease;
    transition: 0.35s all ease;
}


.link:hover {
    /*color: #3f3a37;*/
    /*background-color: rgba(255, 255, 255, 0.56);*/
    background-color: rgba(242, 182, 50, 0.89);
}

a, a:hover {
    color: white;
    text-decoration: none;
}
