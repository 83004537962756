.topbar {
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*padding: 10px 10% 10px 10%;*/
    /*padding-right: 10%;*/
    min-height: 40px;
    align-items: center;
}

.topbar-social {
    width: 100%;
    max-width: 200px;
    padding-right: 100px;
    display: flex;
    justify-content: space-around;
}

.topbar-social-icon {
    /*margin: 7px 5px 5px 7px;*/
    color: #3f3a37;
    -webkit-transition : 0.35s all ease;
    -moz-transition : 0.35s all ease;
    -o-transition : 0.35s all ease;
    transition: 0.35s all ease;
}

.topbar-social-icon:hover {
    color: #baafaf;
}

@media (max-width: 800px) {
    .topbar-social {
        max-width: 180px;
        padding-right: 30px;
    }
}
